<template>
  <div id="app">
    <div class="pageContainer">
      <a-config-provider :locale="locale">
        <router-view />
      </a-config-provider>
    </div>
    <div class="footerTips">本平台内容为原创内容，未经允许禁止转载，盗版必究</div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
export default defineComponent({
  setup() {
    onMounted(() => {
    })
    return {
      locale: zhCN,
      dayjs
    };
  },
});
</script>
<style>
.ant-carousel .slick-active button{
  background: #0960bd!important;
}
body {
  width: 100%;
  margin: 0;
  background: #f0f2f5 !important;
}
#app {
  width: 100%;
  height: 100%;
  background: #f0f2f5;
  color: #333333;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --theme: #0960bd;
  line-height: 1;
}

@import './assets/css/config.css';
@import './assets/css/ajstyle.css';


.footer-p {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.ellipsis1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ellipsis3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@keyframes retateRe {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.roteate {
  transform-origin: center;
  animation: retateRe 2s infinite linear
}


.page-container {
  width: 100%;
}

.self-ghost-btn {
  height: 32px;
  width: 80px;
  line-height: 32px;
  background: rgba(227, 77, 78, 0.24);
  border-radius: 4px;
  border: 1px solid #E34D4E;
  box-sizing: border-box;
  color: #FFFFFF;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

}

.self-primary-btn {
  height: 24px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E34D4E;
  border-radius: 2px;
  color: #FFFFFF;
  font-size: 12px;
  cursor: pointer;
}

.baise {
  color: #FFFFFF;
}

.f12 {
  font-size: 12px;
}

.f13 {
  font-size: 13px;
}

.f14 {
  font-size: 14px;
}

.f16 {
  font-size: 16px;
}

.f15 {
  font-size: 15px;
}

.f18 {
  font-size: 18px;
}

.f20 {
  font-size: 20px;
}

.f24 {
  font-size: 24px;
}

.br10 {
  border-radius: 10px;
}

.br15 {
  border-radius: 15px;
}

.br20 {
  border-radius: 20px;
}

.vline {
  width: 15px;
  height: 0px;
  border: 1px solid #999;
  transform: rotate(90deg);
}

.redXin {
  display: inline-block;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
}

.add-btn {
  background: #FFFFFF;
  border-radius: 2px;
  opacity: 1;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #000;
  height: 24px;
  line-height: 24px;
  padding: 0 10px;
  font-size: 12px;
  margin: 16px 8px 16px 0;
  cursor: pointer;
}
.pageContainer{
  padding-bottom:46px;
  min-width: 1300px;
}
.footerTips{
  position: fixed;
  bottom: 0;
  padding: 16px;
  width: 100%;
  text-align: center;
  color: #999999;
  background-color: #f0f2f5;
}
</style>
